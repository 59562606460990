<template>
  <div id="nav">
    <headerCom></headerCom>
    <router-view />
    <div class="ap-fromdiv position-fixed">
      <!-- <froms></froms> -->
    </div>
    <!-- 底部固定 -->
    <footerCom></footerCom>
  </div>
</template>
<script>
// import froms from './components/froms.vue'
import footerCom from "./components/footer.vue"
import headerCom from "./components/header.vue"
export default {
    components: {
        footerCom, headerCom
    },
    props: {},
    data() {
        return {

        }
    },
}
</script>
<style lang="scss">
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: PingFang SC;
    list-style: none;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
html {
    /*font-size: 5.21vw;*/
    font-size: 100px;
    width: 100%;
    /*overflow-x: hidden;*/
}
.my-container {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    min-height: 50px;
}

.page1200 {
    width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
}
// 底部表单
.ap-fromdiv {
    bottom: 0.3rem;
    transform: scale(0.89);
    z-index: 101;
}
</style>
