<template>
  <div class="header">
    <div class="toptaboth mx-auto d-flex">
      <div>
        <img :src="require('@/assets/header/logo.png')" class="imgs" @click="toindexBtn" />
      </div>
      <div class="nav-wrap d-flex justify-content-between">
        <div>
          <span class="navli" :class="(pathName == 'Index' || pathName=='') ? 'active' : ''">
            <router-link to="/" :class="(pathName == 'Index' || pathName=='') ? 'spanhovother' : 'spantxtother'">首页</router-link>
          </span>
          <el-dropdown placement="bottom" @command="handleCommand">
            <span class="navli drop" :class="(pathName == 'Purchases' || pathName == 'Supply') ? 'activeColor' : ''">
              产品中心<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="purchases">企业一站式采销平台</el-dropdown-item>
              <el-dropdown-item command="supply">聚合供应链</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown placement="bottom" @command="handleCommand">
            <span class="navli drop" :class="(pathName == 'Collection' || pathName == 'Benefits' || pathName == 'Group' || pathName == 'Integral') ? 'activeColor' : ''">
              行业解决方案<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="collection">政企集采</el-dropdown-item>
              <el-dropdown-item command="benefits">政企福利</el-dropdown-item>
              <el-dropdown-item command="group">团购/分销</el-dropdown-item>
              <el-dropdown-item command="integral">积分商城</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span class="navli" :class="pathName == 'Service' ? 'active' : ''">
            <router-link to="/service" :class="pathName == 'Service' ? 'spanhovother' : 'spantxtother'">服务支撑</router-link>
          </span>
          <span class="navli" :class="pathName == 'About' ? 'active' : ''">
            <router-link to="/about" :class="pathName == 'About' ? 'spanhovother' : 'spantxtother'">关于我们</router-link>
          </span>
        </div>
        <!-- 
          体验入口
          <div>
          <a class="nav-experience" href="http://taste-mall.wap.xinfutongtech.com/uat-mall-shop2/#/experience" title="立即体验" target="_blank">立即体验</a>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            pathName: ''
        }
    },
    mounted() {
        this.$router.afterEach((to, from, next) => {
            // eslint-disable-line no-unused-vars
            window.scrollTo(0, 0)
        })
    },
    watch: {
        $route: {
            handler(newRouter) {
                this.pathName = newRouter.name
            },
            immediate: false
        }
    },
    methods: {
        // 跳转首页
        toindexBtn() {
            this.$router.push({
                path: '/index'
            })
        },
        handleCommand(name) {
            this.$router.push({
                path: '/' + name
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.header {
    position: absolute;
    left: 50%;
    margin-left: -600px;
    z-index: 100;
}
.toptaboth {
    width: 1200px;
    height: 80px;
    line-height: 0;
    background: transparent;
    .imgs {
        width: 150px;
        height: 100%;
    }
}
.spantxtother {
    color: rgba(0, 0, 0, 0.85);
}
.spanhovother {
    color: #0070f3;
}
.nav-wrap {
    padding-left: 190px;
    width: 1050px;
    padding-top: 4px;
}
.navli {
    margin-right: 20px;
    margin-left: 20px;
    height: 100%;
    &.active {
        border-bottom: 4px solid #0070f3;
    }

    &.drop {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        &.activeColor {
            color: #0070f3;
        }
    }
    .spanhovother,
    .spantxtother {
        font-size: 16px;
    }
}
.nav-experience {
    width: 96px;
    text-align: center;
    height: 38px;
    line-height: 38px;
    background: #0070f3;
    border-radius: 8px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    display: inline-block;
}
</style>