import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
Vue.prototype.$axios = axios;
import dragscroll from './directive/dragscroll';


Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.directive('dragscroll', dragscroll)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
