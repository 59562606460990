<template>
  <div class="index-bg">
    <!-- banner的滚动 -->
    <div class="i-banner">
      <el-carousel height="640px">
        <el-carousel-item>
          <div class="b1"></div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="b2"></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- number数数字动画 -->
    <div class="i-data-wrap">
      <div class="i-data d-flex">
        <div class="i-item d-flex">
          <div>
            <img src="../assets/index/icon_company.png" />
          </div>
          <div>
            <countTo class="num" :separator="''" :startVal="0" :endVal="1500" :duration="2000"></countTo>
            <span class="unit">家+</span>
            <span class="msg">服务企业</span>
          </div>
        </div>
        <div class="i-item d-flex">
          <div>
            <img src="../assets/index/icon_user.png" />
          </div>
          <div>
            <span class="num">1.2</span><span class="unit">亿+</span>
            <span class="msg">覆盖用户</span>
          </div>
        </div>
        <div class="i-item d-flex">
          <div>
            <img src="../assets/index/icon_sku.png" />
          </div>
          <div>
            <countTo class="num" :separator="''" :startVal="0" :endVal="1500" :duration="2000"></countTo>
            <span class="unit">万+</span>
            <span class="msg">海量SKU</span>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 10px;">
      <div class="page1200">
        <div class="tit">企业一站式采销平台</div>
        <div class="tit-msg" style="margin-bottom: 40px;">借助电商的购物平台模式，为企业搭建专属场景化商城，满足集中采购、劳保福利、私域零售等多场景经营需求，助力企业打通采、销数字链条，实现业绩二次增长</div>
        <div>
          <img :src="require('@/assets/index/enterprise.png')" />
        </div>
      </div>
    </div>

    <div style="margin-top: 80px;">
      <div class="page1200">
        <div class="tit">聚合供应链服务平台</div>
        <div class="tit-msg" style="margin-bottom: 40px;">平台通过API标准接口方式，将海量美妆、食品、电器、家居、母婴、日化、生活服务等全品类、高毛利商品进行输出，满足企业会员拉新、内部采购、积分兑换等需求。平台所有商品支持一件代发和7天无理由退换</div>
        <div>
          <img :src="require('@/assets/index/supplyChain.png')" />
        </div>
      </div>
    </div>

    <div style="margin-top: 120px;">
      <div class="page1200">
        <div class="tit">成功案例</div>
        <div class="tit-msg">累积服务2000+客户，用专业和服务助力企业共赢</div>
        <div class="an-wrap">
          <div v-dragscroll>
            <div class="an-one d-flex">
              <div v-for="index of 16" :key="index">
                <img :src="require('@/assets/index/an'+index+'.png')" />
              </div>
            </div>
            <div class="an-two d-flex">
              <div v-for="index of 16" :key="index">
                <img :src="require('@/assets/index/an'+(16+index)+'.png')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="solution-wrap">
      <div class="page1200">
        <div class="tit">行业解决方案</div>
        <div class="so-wrap-item d-flex">
          <div class="so-item one" @mouseover="setSoIndex('one')" :class="{active:soIndex=='one'}">
            <div class="item-wrap">
              <div class="left">政企集采</div>
              <div class="right" @click="handleCommand('collection')">了解详情 ></div>
            </div>
          </div>
          <div class="so-item two" @mouseover="setSoIndex('two')" :class="{active:soIndex=='two'}">
            <div class="item-wrap">
              <div class="left">政企福利</div>
              <div class="right" @click="handleCommand('benefits')">了解详情 ></div>
            </div>
          </div>
          <div class="so-item three" @mouseover="setSoIndex('three')" :class="{active:soIndex=='three'}">
            <div class="item-wrap">
              <div class="left">团购/分销</div>
              <div class="right" @click="handleCommand('group')">了解详情 ></div>
            </div>
          </div>
          <div class="so-item four" @mouseover="setSoIndex('four')" :class="{active:soIndex=='four'}">
            <div class="item-wrap">
              <div class="left">积分商城</div>
              <div class="right" @click="handleCommand('integral')">了解详情 ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import countTo from "vue-count-to"
export default {
    components: {
        countTo
    },
    props: {},
    data() {
        return {
            soIndex: 'one'
        }
    },
    mounted() {

    },
    methods: {
        setSoIndex(typeStr) {
            this.soIndex = typeStr
        },
        handleCommand(name) {
            this.$router.push({
                path: '/' + name
            })
        }
    }
}
</script>
<style scoped lang="scss">
////////////
$bgColor: #f6f8fc;
.index-bg {
    background: $bgColor;
    min-width: 1200px;
}
.tit {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 38px;
    text-align: center;
    margin-bottom: 16px;
}
.tit-msg {
    height: 44px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 19px;
    text-align: center;
    width: 790px;
    margin: 0 auto;
    line-height: 30px;
}
.b1,
.b2 {
    width: 100%;
    height: 100%;
    min-width: 1200px;
}
.b1 {
    background: url("../assets/index/banner1.png") no-repeat;
    background-position: 50% 50%; /*图片上下左右居中*/
    background-size: cover;
}
.b2 {
    background: url("../assets/index/banner.png") no-repeat;
    background-position: 50% 50%; /*图片上下左右居中*/
    background-size: cover;
}
.i-data-wrap {
    height: 150px;
    position: relative;
    background: $bgColor;
    .i-data {
        width: 1200px;
        height: 150px;
        top: -40px;
        left: 50%;
        margin-left: -600px;
        position: absolute;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 24px;
        z-index: 100;
        padding: 32px 80px;
        .i-item {
            flex: 1;
            line-height: 0px;
            position: relative;
            padding-left: 50px;
            &::after {
                content: "";
                height: 30px;
                width: 2px;
                background: #92bff3;
                line-height: 0px;
                position: absolute;
                right: 0px;
                top: 30px;
            }
            &:last-child {
                &::after {
                    background: transparent;
                }
            }
            > div:first-child {
                margin-right: 20px;
            }
        }
        .num {
            font-size: 48px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
        }
        .unit {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
        }
        .msg {
            font-size: 16px;
            display: block;
            margin-left: 8px;
            margin-top: 28px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
}
.an-wrap {
    cursor: pointer;
    > div {
        display: inline-block;
        line-height: 0px;
        width: 1200px;
        overflow: hidden;
    }
    .an-one {
        margin-bottom: 16px;
    }
    .an-two {
        margin-bottom: 80px;
    }
    .an-one,
    .an-two {
        > div {
            margin-right: 16px;
        }
    }
}
.solution-wrap {
    background: #fff;
    padding-bottom: 80px;
    .tit {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 0px;
    }
    .so-item {
        height: 360px;
        width: 180px;
        border-radius: 24px;
        margin-right: 16px;
        transition: all 0.5s;
        overflow: hidden;
        .item-wrap {
            font-size: 20px;
            font-weight: 600;
            display: flex;
            padding: 290px 40px 0px 40px;
            justify-content: space-between;
            min-width: 360px;
        }
        .left {
            color: rgba(0, 0, 0, 0.85);
        }
        .right {
            color: #0070f3;
            display: none;
            cursor: pointer;
        }
        &.active {
            width: 610px;
            transition: all 0.5s;
            .right {
                display: block;
            }
            &.one {
                background-position-x: 0px;
                transition: all 0.5s;
            }
            &.two {
                background-position-x: 0px;
                transition: all 0.5s;
            }
            &.three {
                background-position-x: 0px;
                transition: all 0.5s;
            }
            &.four {
                background-position-x: 0px;
                transition: all 0.5s;
            }
        }
        &.one {
            background: url("../assets/index/solution1.png") -210px 0px no-repeat;
        }
        &.two {
            background: url("../assets/index/solution2.png") -210px 0px no-repeat;
        }
        &.three {
            background: url("../assets/index/solution3.png") -210px 0px no-repeat;
        }
        &.four {
            background: url("../assets/index/solution4.png") -210px 0px no-repeat;
        }
    }
}
</style>
